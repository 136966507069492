<template>
  <div class="projectspage">
    
    <div class="project_category">
      <button @click="setCategory('Architecture')" class="category_button">ARCHITECTURE</button>
      <button @click="setCategory(['Urban Planning','Landscape'])" class="category_button">URBAN/LANDSCAPE</button>
      <button @click="setCategory('Interior')" class="category_button">INTERIOR</button>
    </div>
    
    <div class="project_list">
      
      <div class="project_box" v-for="project in filteredProjects()" :key="project.name">
        <img :src="getImageUrl(project.images[0])" class="project_image">
        <h1 class="project_title">{{project.name}}</h1>
        <h1 class="project_title_CN">{{project.nameCN}}</h1>
        <router-link :to="{ name:'ProjectDetail', params:{name:project.name}}" class="project_info">
          <button class="project_info_button">Read More</button>
        </router-link>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectPage',
  data(){
    return{
      projects:[],
      selectedCategory:'',
    };
  },
  created(){
    this.loadProjects();
  },
  computed:{
    
    
  },
  methods:{
    setCategory(category){
      this.selectedCategory = category
    },

    filteredProjects(){
      if(!this.selectedCategory){
        return this.projects;
      }
      if(Array.isArray(this.selectedCategory)){
        return this.projects.filter(project =>
          project.typeindex && this.selectedCategory.some(category => project.typeindex.includes(category)));
      }else{
        return this.projects.filter(project =>
          project.typeindex && project.typeindex.includes(this.selectedCategory))
      }
    },
    
    async loadProjects(){
      try {
        const response = await fetch('/projects.json');
        if(!response.ok){
          throw new Error('Network response was not OK');
        }
        const data = await response.json();
        this.projects = data;
      }catch(error){
        console.error("Error loading projects", error);
      }
    },
    getImageUrl(path){
      return `${path}`;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

*{
  margin: 0;
  padding: 0;
}


.project_category{
  margin-top: 12vh;
  margin-left: 30vw;
  margin-right: 30vw;
  height: 15vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

@media (max-width:600px) {
  .project_category{
    margin-left: 2vw;
    margin-right: 2vw;
  }

}


.category_button{
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  background-color: rgb(240,240,240);
  border: none;
  color:rgb(125, 125, 125);

  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 100;
  font-style:inherit;
  font-size: 18px;

  transition: color 0.3s ease, background-color 0.3s ease;
}

@media (max-width:600px) {
  .category_button{
    font-size: 16px;
  }
}


.category_button:hover{
  background-color: black;
  color: white;
}

.project_list{
  margin-right: 17vw;
  margin-left: 17vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width:600px) {
  .project_list{
    margin-right: 2vw;
    margin-left:2vw;
    grid-template-columns: 1fr 1fr;
  }
}


.project_box{
  margin: 1vw;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  object-fit: contain;
}

@media (max-width:600px) {
  .project_box{
    height: 40vh;
  }
}

.project_image{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_box::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(99, 99, 99, 0.45);
  z-index: 1;
}

.project_box h1{
  color: white;
}

.project_title{
  font-family: 'Raleway', sans-serif;
  font-weight: 350;
  font-size: 27px;
  z-index: 2;
}

.project_title_CN{
  font-family: 'Raleway', sans-serif;
  font-weight: 250;
  font-size: 18px;
  z-index: 2;
}

@media (max-width:600px) {

  .project_title{
    font-size: 24px;
  }
  .project_title_CN{
    font-size: 16px;
  }
}

.project_info{
  margin-top: 2vh;
  width: 100%;
  z-index: 2;
}

.project_info_button{
  width: 40%;
  height: 4vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 360;
  font-size: 16px;
  background-color: black;
  color: white;
  border: solid 1px white;
}

@media (max-width:600px) {
  .project_info_button{
    width: 75%;
  }
}

.project_info_button:hover{
  width: 40%;
  height: 4vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 360;
  font-size: 16px;
  background-color: white;
  color: black;
  border: solid 1px white;
}

</style>